const environmentMap = {
  ["mytruckadv-cdt"]: "cdt-dev",
  ["mytruck-cdt"]: "cdt-test",
  ["mytruckedu-cdt"]: "cdt-edu",
  ["mytruck-stage"]: "preprod",
  ["mytruck"]: "prod",
};

function getEnvironmentIdentifier() {
  const domainElements = window.location.host.split(".");
  return domainElements.shift();
}

export default class Environments {
  constructor() {
    const environmentIdentifier = getEnvironmentIdentifier();
    const env = environmentMap[environmentIdentifier];

    const CONSTANTS = {
      AUTHORITY: "https://accounts-cdt.maersk.com/thirdparty-cdt",
      CLIENT_ID: "thirdparty",
      LOGOUT_URI: "https://accounts-cdt.maersk.com/thirdparty-cdt/auth/logout",
      REDIRECT_PATH: "login-callback",
      BASE_URI: window.location.origin,
    };

    let VARS = {};
    switch (env) {
      case "cdt-dev":
        VARS = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckadv-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckadv-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckadv-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.mytruckadv-expiry-date",
        };
        break;
      case "cdt-test":
        VARS = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckcdt-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckcdt-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckcdt-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.mytruckcdt-expiry-date",
        };
        break;
      case "cdt-edu":
        VARS = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckedu-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckedu-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckedu-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.mytruckedu-expiry-date",
        };
        break;
      case "preprod":
        VARS = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckstage-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckstage-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckstage-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.mytruckstage-expiry-date",
        };
        break;
      case "prod":
        VARS = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruck-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruck-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruck-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.mytruck-expiry-date",
        };
        break;

      default:
        throw new Error(`Unknown Environment ${env}`);
    }
    this.configs = Object.assign(CONSTANTS, VARS);
  }
  config(config_name) {
    return this.configs[config_name];
  }
}
