<template>
  <div class="mds-rich-text">
    <h1>{{ message }}</h1>
  </div>
</template>

<script>
import { messages } from "./static"
import authorization  from "../mixins/authorization";

export default {
  mixins: [authorization],

  data() {
    return {
      message: "",
    };
  },

  mounted() {
    if (!this.$al.storage.access_token) {
      this.clearLocalStorage();
      this.removeCookies();
      this.login();
    } else if (this.isTokenExpired) {
      this.message = messages.redirect;
      this.clearLocalStorage();
      this.removeCookies();
      this.login();
    } else {
      this.setCookies(this.$al.storage);
      this.redirectToTargetApplicaiton();
    }
  },
};
</script>
