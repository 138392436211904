import Vue from "vue";
import VueRouter from "vue-router";
import { names } from "./static";
import LoginView from "../views/LoginView.vue";
import LogoutView from "../views/LogoutView.vue";
import RedirectView from "../views/RedirectView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: names.login,
    component: LoginView,
  },
  {
    path: "/login-callback",
    name: names.redirect,
    component: RedirectView,
  },
  {
    path: "/logout",
    name: names.logout,
    component: LogoutView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
