<template>
  <div class="mds-rich-text">
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { messages } from "./static";
import authorization  from "../mixins/authorization";

export default {
  mixins: [authorization],
  data() {
    return {
      seconds: 1,
      message: messages.logout,
    };
  },
  mounted() {
    //set a timeout so we can give AL.js some more time before accessing localstorage, because if we don't do this ALJS accesses it too early, sometimes retrieving the previous/null value from localstorage
    this.removeCookies();
    this.clearLocalStorage();
    setTimeout(() => {
      this.redirectToLogout();
    }, this.seconds * 1000);
  },
};
</script>
